<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
			</div>
			<div class="col">
				<b-button
					v-if="$can('agency_promo', 'create')"
					v-b-modal="'schedule_agency_promo_modal'"
					class="float-right"
					variant="success">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearAllFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="title"
								class="label">{{ translate('type') }}</label>
							<select
								id="type"
								v-model="filters.type"
								type="text"
								class="form-control w-100 my-1 my-0 py-0"
								name="type">
								<option
									value="undefined"
									selected
									disabled>
									{{ translate('type') }}
								</option>
								<option
									v-for="type in promoTypes"
									:key="type.id"
									:value="type.id">
									{{ translate(type.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th class="border-top-0">
									{{ translate('type') }}
								</th>
								<th class="border-top-0">
									{{ translate('description') }}
								</th>
								<th class="border-top-0">
									{{ translate('discount') }}
								</th>
								<th class="border-top-0">
									{{ translate('start_date') }}
								</th>
								<th class="border-top-0">
									{{ translate('end_date') }}
								</th>
								<th class="border-top-0">
									{{ translate('link_code') }}
								</th>
								<th class="border-top-0">
									{{ translate('status') }}
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.type) }}
								</td>
								<td class="align-middle white-space-normal cell-custom-width">
									{{ item.attributes.description }}
								</td>
								<td class="align-middle">
									{{ item.attributes.discount }}%
								</td>
								<td class="align-middle">
									{{ item.attributes.from_date }}
									<!--{{ // $moment(item.attributes.from_date).format(dateFormat) }}-->
								</td>
								<td class="align-middle">
									{{ item.attributes.to_date }}
									<!--{{ // $moment(item.attributes.to_date).format(dateFormat) }}-->
								</td>
								<td class="align-middle">
									{{ item.attributes.link_code }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.status) }}
								</td>
								<td class="align-middle text-center">
									<b-button
										v-b-tooltip.hover
										:title="translate('delete')"
										class="btn mx-1 bg-primary-alt"
										@click="deletePromo(item.id)">
										<i
											class="fa fa-trash"
											aria-hidden="true" />
									</b-button>
									<b-button
										v-if="item.attributes.query_link.length > 0"
										v-b-tooltip.hover
										:title="translate('copy_nootropics_link')"
										class="btn mx-1 bg-primary-alt"
										@click="copyLink(item.attributes.query_link, 'nootropics')">
										<i
											class="fa fa-copy"
											aria-hidden="true" />
									</b-button>
									<b-button
										v-if="item.attributes.query_link.length > 0"
										v-b-tooltip.hover
										:title="translate('copy_direct_link')"
										class="btn mx-1 bg-primary-alt"
										@click="copyLink(item.attributes.query_link, 'direct')">
										<i
											class="fa fa-copy"
											aria-hidden="true" />
									</b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<schedule-promo-modal
			@promo-scheduled="getAgencyDiscounts" />
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	AgencyPromos as messages, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import ReplicatedSite from '@/util/ReplicatedSite';
import DataFilter from '@/components/DataFilter';
import SchedulePromoModal from './components/SchedulePromoModal.vue';
import {
	MMMDYHMS_FORMAT,
	PERIOD_FILTERS as dateRanges,
	ALL_TIME_PERIOD_FILTERS as allTimeDateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	allTime,
	custom,
} from '@/settings/Dates';

export default {
	name: 'AgencyPromotions',
	messages: [messages, Grids],
	components: {
		DataFilter,
		SchedulePromoModal,
	},
	mixins: [FiltersParams],
	data() {
		return {
			agencyPromos: new ReplicatedSite(),
			agencyTypes: new ReplicatedSite(),
			agencyPromoDelete: new ReplicatedSite(),
			dateFormat: MMMDYHMS_FORMAT,
			dateRanges,
			allTimeDateRanges,
			allTime,
			custom,
			calendarConfig,
			alert: new this.$Alert(),
			maxCountries: 4,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.agencyPromos.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.agencyPromos.data.errors;
		},
		pagination() {
			return this.agencyPromos.data.pagination;
		},
		data() {
			try {
				const { data } = this.agencyPromos.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
		promoTypes() {
			try {
				return this.agencyTypes.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.agencyTypes.getPromoTypes();
		this.getAgencyDiscounts();
	},
	methods: {
		copyLink(query, page) {
			const url = `${window.location.origin}/${page}/store${query}`;
			this.$clipboard(url);
			this.alert.toast('success', this.translate('link_copied'));
		},
		getAgencyDiscounts() {
			let options;
			const { query } = this.$route;
			if (Object.keys(query).length > 0) {
				options = query;
			}
			this.agencyPromos.getAgencyPromotions(options);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			Object.keys(options).forEach((key) => {
				if (options[key] === null) {
					delete options[key];
				}
			});

			this.agencyPromos.getAgencyPromotions(options);
		},
		clearAllFilters() {
			this.filters = {};
			this.dateRange = '';
			this.clearFilters();
			this.getAgencyDiscounts();
		},
		deletePromo(id) {
			const trans = {
				title: this.translate('delete_promotion'),
				text: this.translate('delete_promotion_message'),
			};
			const options = {
				confirmButtonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
				reverseButtons: true,
			};

			this.alert.confirmation(trans.title, trans.text, options).then(() => {
				this.agencyPromoDelete.deactivateAgencyPromo(id).then(() => {
					try {
						this.alert.toast('success', this.translate('promotion_deleted'));
					} finally {
						this.getAgencyDiscounts();
					}
				}).catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
			}).catch(() => {});
		},
	},
};
</script>

<style scoped>
.cell-custom-width {
	min-width: 300px;
	max-width: 350px;
}
</style>
