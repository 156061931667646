<template>
	<b-modal
		id="schedule_agency_promo_modal"
		:cancel-disabled="loading"
		:ok-disabled="loading"
		centered
		size="lg"
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
		@ok.prevent="schedulePromo()"
		@cancel="clearAll()">
		<template v-slot:modal-title>
			{{ translate('schedule_promo') }}
		</template>
		<template v-slot:modal-ok>
			<i
				v-if="loading"
				class="fas fa-spinner fa-spin" />
			{{ translate('create') }}
		</template>
		<div>
			<form
				@submit.prevent="null"
				@keydown="clearError($event.target.name);"
				@change="clearError($event.target.name);">
				<b-row>
					<b-col>
						<b-form-group
							label-for="type">
							<slot name="label">
								<label>
									{{ translate('type') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<select
								id="type"
								v-model="form.type"
								type="text"
								class="form-control w-100 my-1 my-0 py-0"
								name="type">
								<option
									value=""
									selected
									disabled>
									{{ translate('type') }}
								</option>
								<option
									v-for="type in promoTypes"
									:key="type.id"
									:value="type.id">
									{{ translate(type.attributes.code_name) }}
								</option>
							</select>
							<template v-if="hasError('type')">
								<span
									v-for="error in errors['type']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group
							label-for="discount">
							<slot name="label">
								<label>
									{{ translate('discount') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-input
								id="discount"
								v-model="form.discount"
								:placeholder="translate('discount')"
								:class="hasError('discount') ? 'is-invalid' : ''"
								name="discount"
								type="number" />
							<template v-if="hasError('discount')">
								<span
									v-for="error in errors['discount']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-form-group>
							<slot name="start_date">
								<label>
									{{ translate('start_date') }}<span class="text-danger">*</span>
								</label>
							</slot>
							<div
								:class="hasError('start_date') ? 'is-invalid' : ''"
								:style="'height: auto'"
								class="form-control border-0">
								<div class="row custom-gutter">
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedStartYear"
											v-model="selectedStartYear"
											type="text"
											class="form-control w-100 my-1"
											name="year"
											@change="updateDays('start')">
											<option
												value=""
												selected
												disabled>
												{{ translate('year') }}
											</option>
											<option
												v-for="year in years"
												:key="year"
												:value="year">
												{{ year }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedStartMonth"
											v-model="selectedStartMonth"
											type="text"
											class="form-control w-100 my-1"
											name="month"
											@change="updateDays('start')">
											<option
												value=""
												selected
												disabled>
												{{ translate('month') }}
											</option>
											<option
												v-for="month in months"
												:key="month"
												:value="month">
												{{ month }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedStartDay"
											v-model="selectedStartDay"
											type="text"
											class="form-control w-100 my-1"
											name="day">
											<option
												value=""
												selected
												disabled>
												{{ translate('day') }}
											</option>
											<option
												v-for="day in startDays"
												:key="day"
												:value="day">
												{{ day }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4">
										<select
											id="selectedStartHour"
											v-model="selectedStartHour"
											type="text"
											class="form-control w-100 my-1"
											name="hour">
											<option
												value=""
												selected
												disabled>
												{{ translate('hour') }}
											</option>
											<option
												v-for="hour in hours"
												:key="hour"
												:value="hour">
												{{ hour }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4">
										<select
											id="selectedStartMinute"
											v-model="selectedStartMinute"
											type="text"
											class="form-control w-100 my-1"
											name="minute">
											<option
												value=""
												selected
												disabled>
												{{ translate('minute') }}
											</option>
											<option
												v-for="minute in minutes"
												:key="minute"
												:value="minute">
												{{ minute }}
											</option>
										</select>
									</div>
									<div
										v-if="form.start_date"
										class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
										role="alert">
										{{ form.start_date }}
									</div>
									<template v-if="hasError('start_date')">
										<span
											v-for="error in errors['start_date']"
											:key="error"
											class="custom-invalid-feedback animated fadeIn px-2"
											v-text="error" />
									</template>
								</div>
							</div>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<slot name="end_date">
								<label>
									{{ translate('end_date') }}<span class="text-danger">*</span>
								</label>
							</slot>
							<div
								:class="hasError('end_date') ? 'is-invalid' : ''"
								:style="'height: auto'"
								class="form-control border-0">
								<div class="row custom-gutter">
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedEndYear"
											v-model="selectedEndYear"
											type="text"
											class="form-control w-100 my-1"
											name="year"
											@change="updateDays('end')">
											<option
												value=""
												selected>
												{{ translate('year') }}
											</option>
											<option
												v-for="year in years"
												:key="year"
												:value="year">
												{{ year }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedEndMonth"
											v-model="selectedEndMonth"
											type="text"
											class="form-control w-100 my-1"
											name="month"
											@change="updateDays('end')">
											<option
												value=""
												selected>
												{{ translate('month') }}
											</option>
											<option
												v-for="month in months"
												:key="month"
												:value="month">
												{{ month }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4 pl-0">
										<select
											id="selectedEndDay"
											v-model="selectedEndDay"
											type="text"
											class="form-control w-100 my-1"
											name="day">
											<option
												value=""
												selected>
												{{ translate('day') }}
											</option>
											<option
												v-for="day in endDays"
												:key="day"
												:value="day">
												{{ day }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
										<select
											id="selectedEndHour"
											v-model="selectedEndHour"
											type="text"
											class="form-control w-100 my-1"
											name="hour">
											<option
												value=""
												selected>
												{{ translate('hour') }}
											</option>
											<option
												v-for="hour in hours"
												:key="hour"
												:value="hour">
												{{ hour }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
										<select
											id="selectedEndMinute"
											v-model="selectedEndMinute"
											type="text"
											class="form-control w-100 my-1"
											name="minute">
											<option
												value=""
												selected>
												{{ translate('minute') }}
											</option>
											<option
												v-for="minute in minutes"
												:key="minute"
												:value="minute">
												{{ minute }}
											</option>
										</select>
									</div>
									<div
										v-if="form.end_date"
										class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
										role="alert">
										{{ form.end_date }}
									</div>
									<template v-if="hasError('end_date')">
										<span
											v-for="error in errors['end_date']"
											:key="error"
											class="custom-invalid-feedback animated fadeIn px-2"
											v-text="error" />
									</template>
								</div>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['desktop_image']"
							class="media-files"
							label-for="desktop_image">
							<slot name="label">
								<label>
									{{ translate('desktop_image') }}
								</label>
							</slot>
							<b-form-file
								id="desktop_image"
								ref="desktop_image"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:class="hasError('desktop_image') ? 'is-invalid' : ''"
								class="text-truncate"
								name="desktop_image"
								type="file" />
							<b-form-text id="desktop_image">
								{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: imgExt }) }}
							</b-form-text>
							<template v-if="hasError('desktop_image')">
								<span
									v-for="error in errors['desktop_image']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['mobile_image']"
							class="media-files"
							label-for="mobile_image">
							<slot name="label">
								<label>
									{{ translate('mobile_image') }}
								</label>
							</slot>
							<b-form-file
								id="mobile_image"
								ref="mobile_image"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:class="hasError('mobile_image') ? 'is-invalid' : ''"
								class="text-truncate"
								name="mobile_image"
								type="file" />
							<b-form-text id="mobile_image">
								{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: imgExt }) }}
							</b-form-text>
							<template v-if="hasError('mobile_image')">
								<span
									v-for="error in errors['mobile_image']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							label-for="description">
							<slot name="label">
								<label>
									{{ translate('description') }}<span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-input
								id="description"
								v-model="form.description"
								:placeholder="translate('description')"
								:class="hasError('description') ? 'is-invalid' : ''"
								name="description"
								type="text" />
							<template v-if="hasError('description')">
								<span
									v-for="error in errors['description']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<!--<b-row>-->
				<!--	<b-col>-->
				<!--		<div class="form-group">-->
				<!--			<div class="form-check">-->
				<!--				<input-->
				<!--					id="show_timer"-->
				<!--					v-model="form.show_timer"-->
				<!--					name="show_timer"-->
				<!--					class="form-check-input"-->
				<!--					type="checkbox">-->
				<!--				<label-->
				<!--					for="show_timer"-->
				<!--					class="label">-->
				<!--					{{ translate('show_timer') }}-->
				<!--				</label>-->
				<!--			</div>-->
				<!--			<template v-if="hasError('show_timer')">-->
				<!--				<span-->
				<!--					v-for="error in errors['show_timer']"-->
				<!--					:key="error"-->
				<!--					class="invalid-feedback animated fadeIn"-->
				<!--					v-text="error" />-->
				<!--			</template>-->
				<!--		</div>-->
				<!--	</b-col>-->
				<!--</b-row>-->
			</form>
		</div>
	</b-modal>
</template>

<script>
import {
	Time as timeTranslations, Products, MediaLibrary, AgencyPromos,
} from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import { IMAGES_EXT as imgExt } from '@/settings/AgencyPromo';
import { SCHEDULE_START_YEAR, YM_FORMAT, YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'SchedulePromoModal',
	messages: [timeTranslations, Products, MediaLibrary, AgencyPromos],
	data() {
		return {
			form: {
				type: '',
			},
			alert: new this.$Alert(),
			agencyPromo: new ReplicatedSite(),
			agencyTypes: new ReplicatedSite(),
			years: [],
			months: [],
			startDays: [],
			endDays: [],
			hours: [],
			minutes: [],
			selectedStartYear: '',
			selectedStartMonth: '',
			selectedStartDay: '',
			selectedStartHour: '',
			selectedStartMinute: '',
			selectedEndYear: '',
			selectedEndMonth: '',
			selectedEndDay: '',
			selectedEndHour: '',
			selectedEndMinute: '',
			YMDHMS_FORMAT,
			YM_FORMAT,
			imgExt,
			loading: false,
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.agencyPromo.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.agencyPromo.data.errors.errors = newErrors;
			},
		},
		promoTypes() {
			try {
				return this.agencyTypes.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		start_date() {
			if (this.selectedStartYear !== '' && this.selectedStartMonth !== '' && this.selectedStartDay !== '' && this.selectedStartHour !== '' && this.selectedStartMinute !== '') {
				return this.createDate(this.selectedStartYear, this.selectedStartMonth, this.selectedStartDay, this.selectedStartHour, this.selectedStartMinute);
			}
			return '';
		},
		end_date() {
			if (this.selectedEndYear !== '' && this.selectedEndMonth !== '' && this.selectedEndDay !== '' && this.selectedEndHour !== '' && this.selectedEndMinute !== '') {
				return this.createDate(this.selectedEndYear, this.selectedEndMonth, this.selectedEndDay, this.selectedEndHour, this.selectedEndMinute);
			}
			return '';
		},
	},
	mounted() {
		this.agencyTypes.getPromoTypes();
		this.populateTime();
	},
	methods: {
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		clearAll() {
			this.selectedStartYear = '';
			this.selectedStartMonth = '';
			this.selectedStartDay = '';
			this.selectedStartHour = '';
			this.selectedStartMinute = '';
			this.selectedEndYear = '';
			this.selectedEndMonth = '';
			this.selectedEndDay = '';
			this.selectedEndHour = '';
			this.selectedEndMinute = '';

			this.form = {
				type: '',
			};
		},
		schedulePromo() {
			const formData = new FormData();
			this.form.desktop_image = undefined;
			if (typeof this.$refs.desktop_image !== 'undefined') {
				const [File] = this.$refs.desktop_image.$refs.input.files;
				this.form.desktop_image = File;
			}

			if (typeof this.$refs.mobile_image !== 'undefined') {
				const [File] = this.$refs.mobile_image.$refs.input.files;
				this.form.mobile_image = File;
			}

			if (typeof this.form.desktop_image !== 'undefined') {
				formData.set('desktop_image', this.form.desktop_image);
			}
			if (typeof this.form.mobile_image !== 'undefined') {
				formData.set('mobile_image', this.form.mobile_image);
			}

			formData.set('test', 'test');
			formData.set('type', this.form.type || null);
			formData.set('discount', this.form.discount || null);
			formData.set('description', this.form.description || null);
			formData.set('start_date', this.start_date || null);
			formData.set('end_date', this.end_date || null);
			formData.set('show_timer', this.form.show_timer || false);

			this.loading = true;
			this.agencyPromo.scheduleAgencyPromo(formData).then(() => {
				this.alert.toast('success', this.translate('promo_scheduled'));
				this.$bvModal.hide('schedule_agency_promo_modal');
				this.$emit('promo-scheduled');
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => {
				this.loading = false;
			});
		},
		// Dates
		populateTime() {
			const startYear = this.$moment(`${SCHEDULE_START_YEAR}`).year();
			for (let i = 0; i <= 59; i += 1) {
				if (i <= 20) this.years.push(startYear + i);
				if (i < 10) {
					this.hours.push(`0${i}`);
					this.minutes.push(`0${i}`);
					if (i > 0) this.months.push(`0${i}`);
				} else {
					if (i <= 23) this.hours.push(`${i}`);
					if (i <= 12) this.months.push(`${i}`);
					this.minutes.push(`${i}`);
				}
			}
		},
		updateDays(id) {
			let year = '';
			let month = '';

			const days = [];
			if (id === 'start') {
				this.startDays = [];
				year = this.selectedStartYear;
				month = this.selectedStartMonth;
			} else if (id === 'end') {
				this.endDays = [];
				year = this.selectedEndYear;
				month = this.selectedEndMonth;
			}
			const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
			for (let i = 1; i <= maxDaysInMonth; i += 1) {
				if (i < 10) {
					days.push(`0${i}`);
				} else {
					days.push(`${i}`);
				}
			}
			if (id === 'start') {
				this.startDays = days;
				this.selectedStartDay = '01';
			} else if (id === 'end') {
				this.endDays = days;
				this.selectedEndDay = '01';
			}
		},
		createDate(year, month, day, hour, minute) {
			if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
				return this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
			}

			return '';
		},
	},
};
</script>

<style scoped>
.form-control {
	border-color: #e4e7ea !important;
	background-image: none !important;
}

.form-control.is-invalid {
	border-color: #f86c6b !important;
}

.form-control.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
	box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
}
</style>
